// @ts-nocheck
export default {
    welcome: "歡迎",
    email_address: "電子郵件地址",
    email_or_phone: "電子郵件地址或電話號碼",
    enter_your_email: "輸入您的電子郵件",
    enter_your_email_or_phone: "輸入您的電子郵件地址或電話號碼",
    phone_placeholder: "電話號碼",
    password: "密碼",
    confirm_password: "確認密碼",
    log_in: "登入",
    login: "登入",
    forgot_password: "忘記密碼？",
    forgot_password_title: "忘記密碼",
    keep_me_logged_in: "保持登入狀態",
    sign_in: "登入",
    not_registered: "還沒有註冊？",
    create_account: "創建帳戶",
    sign_up: "註冊",
    first_name: "名字",
    last_name: "姓氏",
    dob_placeholder: "出生日期 (DD/MM/YYYY)",
    submit: "提交",
    already_have_account: "已經有帳戶？",
    why_need_dob_title: "為什麼我們需要這個？",
    why_need_dob_text: `使用本網站即表示您確認並同意您至少已滿14歲，或者如果您未滿18歲但至少已滿14歲（“未成年人”），則表示您在父母或法定監護人的同意下使用本網站，並且您已獲得父母或法定監護人的許可來使用本網站並同意其條款。如果您是未成年人的父母或法定監護人，您在此同意將未成年人約束於這些條款，並在未成年人違反任何這些條款時完全賠償並使公司免受損害。如果您未滿14歲，您不得在任何時間或以任何方式使用本網站或向公司或網站提交任何信息。注意：本軟件的某些實例要求用戶至少年滿14歲，至少年滿16歲或至少年滿18歲。如果您在輸入出生日期後被拒絕註冊，則是因為您註冊的特定門戶網站有這些要求之一。`,
    go_back: "返回",
    email_verification: "電子郵件驗證",
    log_out: "登出",
    resend_email_verification: "重新發送驗證電子郵件",
    resend_text_verification: "重新發送驗證短信",
    send_email_verification_instead: "改為發送驗證電子郵件",
    send_text_verification_instead: "改為發送驗證短信",
    reset_password: "重置密碼",
    accept_invite: "接受邀請",
    my_hep: "我的家庭運動計劃",
    plan_created: "計劃已創建",
    remove_plan: "移除計劃",
    remove_plan_confirm_text: "您確定要移除此計劃嗎？",
    duplicate_plan_check_text: "您已經分配了此計劃。您確定要重新分配此計劃嗎？",
    duplicate_plan_modal: {
        yes_text: "是",
        no_text: "轉到儀表板",
    },
    translation_disclaimer: {
        title: "翻譯免責聲明",
        subtitle: "請注意，我們的翻譯由人工智慧軟體提供支持，準確率約為 98%。為了確保清晰且準確的溝通，我們建議要求對任何看似不清楚或不正確的翻譯進行澄清。也建議使用簡單的語言並避免使用俚語，以盡量減少潛在的誤解。",
        accept_button_text: "我瞭解並同意自行承擔使用風險",
    },
    provider: "提供者",
    self_help_text: "自助教育",
    show_all: "顯示全部",
    no_record: "未找到記錄",
    no_heps: "您可以在這裡找到您的恢復計劃，這些計劃是由您的醫療保健提供者分配給您後創建的，或者透過我們可能提供給您的任何自助工具自行分配的。目前您尚未分配任何內容。",
    what_to_do: "該怎麼做",
    hold_time: "保持時間",
    day_per_week: "每週天數",
    equipment: "設備",
    sets: "組數",
    reps: "重複次數",
    weight: "重量",
    in_pounds: "以磅計",
    time_per_day: "每天時間",
    starting_position: "起始位置",
    description: "描述",
    what_you_feel: "您應該感受到什麼",
    too_easy: "太容易",
    too_hard: "太難",
    just_right: "剛剛好",
    exercise: "運動",
    education: "教育",
    back_to_list: "返回列表",
    about_this_exercise: "您對這項運動有什麼看法？",
    attachement: "附件",
    exercise_completed: "將練習標記為已完成",
    no_details: "無可用詳細信息。",
    hep_details: {
        hard_exercise_modal: {
            title: "需要調整運動。",
            description_line1:
                "這是此策略中最簡單的運動，因此我們目前無法提供更簡單的運動。",
            description_line2:
                "如果您覺得任何運動太難，有疑慮或不確定下一步該怎麼做，請立即停止並諮詢醫療專業人員或您的指定提供者（如果此恢復計劃是由他們開處方的）。",
            btn_text: "我已閱讀並理解這些建議",
        },
    },
    flash_card: {
        yes_text: "是",
        no_text: "否",
    },
    complete_strategy_modal: {
        title: "恭喜！",
        description: "您已完成這組運動。",
        btn_text: "轉到 HEP 列表",
    },
    talk_to_expert: {
        title: "與專家交談",
        breadcrumb: "與專家交談！",
        book_now: "立即預訂",
        powered_by: "技術支持",
    },
    find_clinic: {
        title: "尋找診所",
        search: "輸入位置",
        breadcrumb: "尋找診所",
        back_title: "位置",
        result_text: "搜索結果",
        pagination_label: "顯示：",
        pagination_text: "條目",
        toggle_list_map_label: "查看：",
        list_text: "列表",
        map_text: "地圖",
        print_text: "打印",
        not_found: "未找到診所",
    },
    messages: {
        title: "消息",
        new_message: "新消息",
        welcome: "歡迎",
        you_are_signed_in_as: "您已登入為",
        hi: "嗨",
        you_are_disconnected: "您目前已斷開網絡連接。",
        practice: "實踐",
        provider: "提供者",
        could_not_send_msg: "無法發送消息。",
        some_files_removed: "一些超過100 MB的文件已被移除。",
        say_hello: "打招呼",
        hello_text: "你好！",
        start_conversation: "開始您的新對話",
        send: "發送",
        textbox_placeholder: "開始輸入...",
        search_placeholder: "搜索...",
        no_provider: "未分配提供者。",
        no_active_chat: "您沒有活躍的聊天。點擊新消息按鈕開始新聊天。",
    },
    header_messages: {
        title: "消息",
        view_all: "查看全部",
        no_messages: "暫無消息顯示",
    },
    support: {
        title: "支持",
        tech_support_email: "技術支持電子郵件",
        other_question: "其他問題",
        section1_title: "臨床支持（與醫療相關的問題）",
        section1_description:
            "在危及生命或醫療緊急情況下，請立即撥打911。對於非緊急醫療問題，請聯繫",
        section2_title: "技術支持（與軟件相關的問題）",
        section2_description:
            "我們的技術支持時間為太平洋標準時間週一至週五上午7:00至下午4:00，主要節假日除外。任何在此時間之外提交的問題將在下一個工作日處理。",
        tech_support_email_address: "support@everflexplus.com",
    },
    injury_prevention_plan: {
        title: "傷害預防計劃",
        heading: "目前沒有受傷？整天坐在辦公桌前？",
        description:
            "開始跑步計劃，或想提高您的高爾夫球技？我們有一些運動可以幫助預防疼痛和受傷，並加強您需要提高表現的部位。",
        workplace_label: "工作場所計劃",
        sport_label: "運動計劃",
        speciality_label: "專業計劃",
        generalized_label: "通用計劃",
        start_plan: "開始計劃",
    },
    footer: {
        copyright_text: "Everflex Plus Health | 版權所有",
        pat_no_text: "這些產品受美國專利號US 11,587,687和其他待審申請的保護。",
        about: "關於",
        security_statement: "安全聲明",
        privacy_policy: "隱私政策",
        terms_conditions: "條款和條件",
        contact: "聯繫",
        support: "支持",
        faqs: "常見問題",
        patent_information_text: "專利信息",
    },
    my_profile: {
        head_title_text: "個人資料",
        my_account_text: "我的帳戶",
        tab: {
            account_information_text: "帳戶信息",
            communication_preferences_text: "通信偏好",
            change_password_text: "更改密碼",
        },
        account_information_form: {
            page_heading_text: "帳戶信息",
            first_name_field: {
                label_text: "名字",
                placeholder_text: "輸入名字",
            },
            last_name_field: {
                label_text: "姓氏",
                placeholder_text: "輸入姓氏",
            },
            dob_field: {
                label_text: "出生日期",
                placeholder_text: "出生日期 (MM/DD/YYYY)",
            },
            email_field: {
                label_text: "電子郵件/用戶名",
                placeholder_text: "輸入電子郵件/用戶名",
            },
            phone_number_field: {
                label_text: "電話號碼",
                placeholder_text: "輸入電話號碼",
            },
            otp_modal: {},
            unverified_email_text: "您的電子郵件/用戶名未經驗證。",
            unverified_email_btn_text: "點擊這裡重新發送驗證電子郵件。",
            verification_link_sent_text:
                "新的驗證鏈接已發送到您的電子郵件地址。",
            verification_link_not_sent: "請提供電子郵件地址以進行驗證。",
            submit_btn_text: "提交",
        },
        change_password_form: {
            page_heading_text: "登入信息",
            current_password_field: {
                label_text: "當前密碼",
                placeholder_text: "輸入當前密碼",
            },
            new_password_field: {
                label_text: "新密碼",
                placeholder_text: "輸入新密碼",
            },
            confirm_password_field: {
                label_text: "確認密碼",
                placeholder_text: "輸入確認密碼",
            },
            submit_btn_text: "提交",
        },
        communication_preference_form: {
            page_heading_text: "通信偏好",
            table_headings: {
                setting_text: "設置",
                opt_in_text: "選擇加入",
                opt_out_text: "選擇退出",
            },
            table_body: {
                email_notification_text: "電子郵件通知",
                sms_notification_text: "短信通知",
            },
            submit_btn_text: "提交",
        },
    },
    password_requirement_text:
        "密碼必須包含至少8個字符，包括大小寫字母、數字和符號的組合。",
    language: {
        could_not_update: "無法更新語言。",
    },
    error_page: {
        go_back_text: "返回首頁",
        403: {
            title: "403：禁止訪問",
            description: "抱歉，您無權訪問此頁面。",
        },
        404: {
            title: "404：頁面未找到",
            description: "抱歉，找不到您要查找的頁面。",
        },
        500: {
            title: "500：服務器錯誤",
            description: "哎呀，我們的服務器出錯了。",
        },
        503: {
            title: "503：服務不可用",
            description: "抱歉，我們正在進行一些維護。請稍後再來。",
        },
    },
    identify_injury: {
        select: {
            front: "正面",
            back: "背面",
            heading: "我的傷害",
            page_title: "我的傷害",
            okay: "好的",
            tell_me: "告訴我",
            about_pain: "關於您的疼痛",
            answer_question:
                "回答一些問題，並獲得一系列個性化的基於證據和物理治療師策劃的運動，旨在幫助您輕柔地緩解23種最常見的疾病。如果您不符合任何傷害模式，您可以使用我們的通用計劃進行自助，找到您附近的物理治療診所，或在線與提供者進行訪問。",
            select_body_part: "選擇您有問題的身體部位：",
            next: "下一步",
            body_part_not_found: "未找到身體部位。",
        },
        show: {
            no_question_found: "未找到問題",
            next: "下一步",
            heading: "識別我的傷害",
            page_title: "識別我的傷害",
            play_video_info:
                "播放視頻以聽取您的虛擬物理治療師對問題的更深入解釋。",
        },
        body_part: {
            heading: (bodyPart) => `探索${bodyPart}評估`,
            sub_heading: (currentBodyPart, newBodyPart) =>
                `未從${currentBodyPart}運動中找到緩解？重定向以評估和解決相關的${newBodyPart}問題。`,
            explore_plans: "探索計劃",
        },
        fallout: {
            heading: "根據您提供的信息，您未落入我們的任何傷害模式中。",
            sub_heading:
                "建議您與持牌物理治療師交談，以確保您獲得最佳護理。我們提供以下選項以滿足您的需求：",
            talk_to_expert: "與專家交談",
            find_clinic: "尋找診所",
        },
        generalized: {
            heading: "探索通用計劃",
            sub_heading:
                "未找到您需要的內容？重定向到更廣泛的選項以管理您的疼痛或增強您的運動。",
            explore_plans: "探索計劃",
            not_ready_to_speak_heading: "還沒準備好與專家交談嗎？探索我們的整體計劃。.",
            not_ready_to_speak_sub_heading: "點擊下面的更多選項來幫助控制您的疼痛或受傷",
        },
        hep: {
            heading: "做得好 🎉",
            sub_heading:
                "您的回答已成功匹配到一個已建立的傷害模式。我們已為您分配了一個與這些結果相匹配的恢復計劃。",
            get_started: "開始",
        },
        all_body_parts: {
            neck: "頸部",
            shoulder: "肩部",
            elbow_hand: "肘部和手部",
            elbow_arm: "肘部和手臂",
            wrist_hand: "手腕和手部",
            back: "背部",
            hip: "髖部",
            knee: "膝部",
            knee_leg: "膝部和腿部",
            ankle_foot: "踝部和足部",
        },
        something_went_wrong_self_help_logs: "保存您的自助日誌時出錯。",
    },
    cms_pages: {
        about: {
            breadcrumb_text: "關於",
            head_text: "關於",
            title_text: "關於",
        },
        security_statement: {
            breadcrumb_text: "安全聲明",
            head_text: "安全聲明",
            title_text: "安全聲明",
        },
        privacy: {
            breadcrumb_text: "隱私政策",
            head_text: "隱私政策",
            title_text: "隱私政策",
        },
        terms: {
            breadcrumb_text: "條款和條件",
            head_text: "條款和條件",
            title_text: "條款和條件",
        },
        faq: {
            breadcrumb_text: "常見問題",
            head_text: "常見問題",
            title_text: "常見問題",
        },
        patent: {
            breadcrumb_text: "專利信息",
            head_text: "專利信息",
            title_text: "專利信息",
        },
        no_data_text: "未找到數據！",
    },
    accept_terms: {
        i_agree_text: "我同意上述條款和條件。",
        yes_btn_text: "是",
        no_btn_text: "否",
    },
    hep_restart: {
        check_in: "是時候簽到了",
        completed: "您的計劃已完成！",
        hey: "你好，",
        complete_plan_desc: `我們很高興與您一起慶祝過去21天您所取得的驚人成就！`,
        take_a_moment: `花點時間反思一下您目前的感受。`,
        feeling_better: `感覺好些了嗎？太棒了！`,
        current_plan_desc: `您可以點擊下面的完成我的當前計劃按鈕。為您的健康乾杯！`,
        resume_plan_desc: `要繼續您的計劃並保持勢頭，請點擊下面的繼續我的計劃按鈕。`,
        clinic_desc: `如果您沒有取得太大進展或需要一些額外的支持，我們會支持您！暫停一下是可以的。我們建議與醫療專業人員交談，他們可以提供個性化的指導。`,
        top_priority: `您的健康是我們的首要任務。`,
        support_you: `我們會在每一步都支持您。`,
        complete_plan_btn: `完成我的當前計劃`,
        resume_plan_btn: `繼續我的計劃`,
        clinic_btn: "尋找您附近的醫療專業人員",
        keep_moment: "您想保持勢頭嗎？",
        is_completed_resume_plan: `點擊下面的繼續我的計劃按鈕。`,
    },
    header_module: {
        notification_section: {
            popup_heading: "通知",
            view_all_text: "查看全部",
            no_data_text: "沒有通知顯示。",
        },
        select_preferred_language: "選擇首選語言",
        my_account: "我的帳戶",
        sign_out: "登出",
        logout_go_back: "登出/返回",
    },
    notification_center: {
        list: {
            page_title: "通知中心列表",
            page_heading: "所有通知",
            no_record: "未找到通知",
        },
        show: {
            page_title: "通知中心顯示",
            page_heading: "通知詳情",
        },
        breadcrumb: {
            notification_text: "所有通知",
        },
        action: {
            view: "查看",
            delete: "刪除",
            delete_confirm: "您確定要刪除此通知嗎？",
        },
    },
    general_error: "出了些問題。請稍後再試。",
    start_screen: {
        title: "輸入您的電子郵件或電話號碼",
        heading: "輸入您的電子郵件或電話號碼",
        email_placeholder: "輸入您的電子郵件或電話號碼",
        btn_text: "下一步",
        account_disabled_error: "您的帳戶已被禁用，請聯繫支持。",
        invite_sent: "邀請電子郵件已成功發送，請檢查您的電子郵件。",
        invite_sent_by_text: "邀請短信已發送",
        send_invite_by_text: "改為發送邀請短信",
        account_not_found: "未找到帳戶！",
        choose_account: "選擇您的診所以繼續！",
    },
    invite_modal: {
        heading: "我們找到了您的帳戶",
        sub_heading:
            "您的帳戶已創建，但註冊過程尚未完成。請檢查您的電子郵件以完成註冊，或在必要時請求新的邀請。",
        btn_text: "重新發送帳戶邀請電子郵件",
        btn_text_sms: "重新發送帳戶邀請短信",
    },
    preference_modal: {
        acceptance_text: (clinicName) =>
            `我授權以下來自${clinicName}的通信通過提供的電子方式傳送給我。我理解某些形式的電子通信可能不安全，存在向未經授權的個人不當披露的風險。`,
        acceptance_text_2: `我願意接受這種風險，如果發生此類事件，我不會追究診所的責任。`,
        sms_notification_text: `短信通知提醒 - 當適用於您的康復計劃並由您的提供者分配時`,
        email_notification_text: `電子郵件通知（重要更新、其他醫療相關信息的提醒和偶爾的營銷消息）`,
        marketing_text: "行銷電子郵件 - 接收根據您的興趣和入口網站活動量身定制的教育內容、服務或產品更新。注意：我們絕不會將您的資訊出售給第三方。",
        clinical_text: "臨床電子郵件 - 接收與您的治療和健康旅程相關的重要更新和資訊。",
        system_text: "系統通知電子郵件 - 接收系統更新和安全警報等重要通知，保持資訊同步。",
        system: {
            header: "系統通知電子郵件",
            subtext: "接收系統更新和安全警報等重要通知，保持資訊同步。",
        },
        clinical: {
            header: "臨床電子郵件",
            subtext: "接收與您的治療和健康旅程相關的重要更新和資訊。",
        },
        marketing: {
            header: "行銷電子郵件",
            subtext: "接收根據您的興趣和入口網站活動量身定制的教育內容、服務或產品更新。注意：我們絕不會將您的資訊出售給第三方。",
        },
        accept_btn_text: "接受",
        submit_btn_text: "提交",
        enter_phone: "輸入您的手機號碼",
        default_timezone_text: (defaultTimezone) =>
            `我們將在當地時間下午6:00發送短信提醒您做運動。看起來您位於${defaultTimezone}時區，對嗎？`,
        timezone_required: "需要時區。",
        saved: "偏好已保存。",
        no_btn_text: "否",
        yes_btn_text: "是",
        select_timezone: "選擇時區",
        select_your_timezone: "選擇您的時區",
    },
    set_new_password: {
        heading: "設置您的新密碼",
        sub_heading:
            "為了增強安全性，請輸入管理員提供的當前密碼，然後設置您的新密碼。",
        current_password_label: "當前密碼",
        current_password_placeholder: "輸入當前密碼",
        new_password_label: "新密碼",
        new_password_placeholder: "輸入新密碼",
        confirm_password_label: "確認新密碼",
        confirm_password_placeholder: "確認密碼",
        save_btn_text: "保存密碼",
    },
    hep_status: {
        completed: "已完成",
        self_assigned: "自我分配",
        independent: "獨立家庭計劃",
    },
    level: "級別",
    sec: "秒",
    days: "天",
    repetitions: "重複次數",
    lbs: "磅",
    time: "時間",
    allLanguages: {
        en: "英語",
        es: "西班牙語",
        fr: "法語",
        zh: "中文（簡體）",
        "zh-TW": "中文（繁體）",
        tl: "他加祿語",
        vi: "越南語",
        ar: "阿拉伯語",
        ko: "韓語",
        ru: "俄語",
        hi: "印地語",
        sw: "斯瓦希里語",
    },
    outcomes: {
        next: "下一步",
        back: "返回",
        continue: "繼續",
        finish: "完成",
        response_title: "感謝您的反饋。",
        groc: {
            title: "評估您的長期進展",
            subtitle: "請回顧您的狀況，與最初就診或治療開始時相比。選擇最能描述自那時以來您所經歷的整體變化的選項。請考慮所有正面和負面的變化，包括任何改善或惡化的方面。",
            text_neg_7: "明顯惡化",
            text_neg_4: "惡化",
            text_0: "無變化",
            text_4: "改善",
            text_7: "明顯改善",
        },
        psfs: {
            title: "評估您的日常活動挑戰",
            subtitle: "請列出 3 項因您的狀況導致無法完成或感到困難的重要活動。對於每項活動，請提供您當前的困難程度評分（以今天為準）。",
            text_0: "無法執行",
            text_10: "可如受傷前一樣執行",
            activity: "活動",
            text_0: "無法執行",
            text_10: "可如受傷前一樣執行",
        }
    }
};
