// @ts-nocheck
export default {
    welcome: "Chào mừng",
    email_address: "Địa chỉ Email",
    email_or_phone: "Địa chỉ Email hoặc Số điện thoại",
    enter_your_email: "Nhập Email của bạn",
    enter_your_email_or_phone: "Nhập Địa chỉ Email hoặc Số điện thoại của bạn",
    phone_placeholder: "Số điện thoại",
    password: "Mật khẩu",
    confirm_password: "Xác nhận Mật khẩu",
    log_in: "Đăng nhập",
    login: "Đăng nhập",
    forgot_password: "Quên Mật khẩu?",
    forgot_password_title: "Quên Mật khẩu",
    keep_me_logged_in: "Giữ tôi đăng nhập",
    sign_in: "Đăng nhập",
    not_registered: "Chưa đăng ký?",
    create_account: "Tạo Tài khoản",
    sign_up: "Đăng ký",
    first_name: "Tên",
    last_name: "Họ",
    dob_placeholder: "Ngày sinh (DD/MM/YYYY)",
    submit: "Gửi",
    already_have_account: "Đã có tài khoản?",
    why_need_dob_title: "Tại sao chúng tôi cần điều này?",
    why_need_dob_text: `Bằng cách sử dụng Trang web này, bạn đại diện, thừa nhận và đồng ý rằng bạn ít nhất 14 tuổi, hoặc nếu bạn dưới 18 tuổi nhưng ít nhất 14 tuổi (một "Người chưa thành niên"), rằng bạn đang sử dụng Trang web với sự đồng ý của cha mẹ hoặc người giám hộ hợp pháp của bạn và rằng bạn đã nhận được sự cho phép của cha mẹ hoặc người giám hộ hợp pháp của bạn để sử dụng Trang web và đồng ý với các Điều khoản của nó. Nếu bạn là cha mẹ hoặc người giám hộ hợp pháp của một Người chưa thành niên, bạn đồng ý ràng buộc Người chưa thành niên với các Điều khoản này và hoàn toàn bồi thường và giữ vô hại Công ty nếu Người chưa thành niên vi phạm bất kỳ Điều khoản nào trong số này. Nếu bạn không ít nhất 14 tuổi, bạn không thể sử dụng Trang web vào bất kỳ lúc nào hoặc theo bất kỳ cách nào hoặc gửi bất kỳ thông tin nào cho Công ty hoặc Trang web. LƯU Ý: Có một số trường hợp của phần mềm này yêu cầu người dùng phải ít nhất 14 tuổi, ít nhất 16 tuổi hoặc ít nhất 18 tuổi. Nếu bạn bị từ chối đăng ký sau khi nhập ngày sinh của mình, đó là vì cổng thông tin cụ thể mà bạn đang đăng ký có một trong những yêu cầu này.`,
    go_back: "Quay lại",
    email_verification: "Xác minh Email",
    log_out: "Đăng xuất",
    resend_email_verification: "Gửi lại Email Xác minh",
    resend_text_verification: "Gửi lại Tin nhắn Xác minh",
    send_email_verification_instead: "Gửi Email Xác minh Thay thế",
    send_text_verification_instead: "Gửi Tin nhắn Xác minh Thay thế",
    reset_password: "Đặt lại Mật khẩu",
    accept_invite: "Chấp nhận Lời mời",
    my_hep: "Kế hoạch Tập thể dục Tại nhà của tôi",
    plan_created: "Kế hoạch Đã tạo",
    remove_plan: "Xóa Kế hoạch",
    remove_plan_confirm_text: "Bạn có chắc chắn muốn xóa kế hoạch này không?",
    duplicate_plan_check_text:
        "Bạn đã có kế hoạch này được chỉ định. Bạn có chắc chắn muốn chỉ định lại kế hoạch này không?",
    duplicate_plan_modal: {
        yes_text: "Có",
        no_text: "Đi đến Bảng điều khiển",
    },
    translation_disclaimer: {
        title: "Tuyên bố từ chối trách nhiệm về dịch thuật",
        subtitle: "Xin lưu ý rằng bản dịch của chúng tôi được hỗ trợ bởi phần mềm AI, độ chính xác khoảng 98%. Để đảm bảo thông tin liên lạc rõ ràng và chính xác, chúng tôi khuyên bạn nên yêu cầu làm rõ bất kỳ bản dịch nào có vẻ không rõ ràng hoặc không chính xác. Bạn cũng nên sử dụng ngôn ngữ đơn giản và tránh tiếng lóng để giảm thiểu những hiểu lầm có thể xảy ra.",
        accept_button_text: "TÔI HIỂU VÀ ĐỒNG Ý SỬ DỤNG RỦI RO CỦA RIÊNG TÔI",
    },
    provider: "Nhà cung cấp",
    self_help_text: "Giáo dục Tự giúp đỡ",
    show_all: "Hiển thị Tất cả",
    no_record: "Không tìm thấy bản ghi",
    no_heps: "Đây là nơi bạn sẽ tìm thấy các Kế hoạch phục hồi được tạo sau khi nhà cung cấp dịch vụ chăm sóc sức khỏe của bạn chỉ định cho bạn hoặc tự chỉ định thông qua bất kỳ công cụ tự trợ giúp nào của chúng tôi có thể có sẵn cho bạn. Hiện tại bạn chưa được giao nhiệm vụ nào.",
    what_to_do: "Phải làm gì",
    hold_time: "Thời gian giữ",
    day_per_week: "Ngày mỗi tuần",
    equipment: "Thiết bị",
    sets: "Bộ",
    reps: "Lần lặp lại",
    weight: "Trọng lượng",
    in_pounds: "Bằng pound",
    time_per_day: "Thời gian mỗi ngày",
    starting_position: "Vị trí bắt đầu",
    description: "Mô tả",
    what_you_feel: "Bạn nên cảm thấy gì",
    too_easy: "Quá dễ",
    too_hard: "Quá khó",
    just_right: "Vừa phải",
    exercise: "Bài tập",
    education: "Giáo dục",
    back_to_list: "Quay lại danh sách",
    about_this_exercise: "Bạn nghĩ gì về bài tập này?",
    attachement: "Tệp đính kèm",
    exercise_completed: "Đánh dấu bài tập là đã hoàn thành",
    no_details: "Không có chi tiết nào.",
    hep_details: {
        hard_exercise_modal: {
            title: "Cần điều chỉnh cho bài tập.",
            description_line1:
                "Đây là bài tập dễ nhất trong chiến lược này, vì vậy chúng tôi không thể cung cấp một bài tập dễ hơn vào lúc này.",
            description_line2:
                "Nếu bạn thấy bất kỳ bài tập nào quá khó, có lo ngại hoặc không chắc chắn phải làm gì tiếp theo, hãy dừng lại ngay lập tức và tham khảo ý kiến Chuyên gia Y tế hoặc Nhà cung cấp được chỉ định của bạn nếu kế hoạch phục hồi này được kê đơn.",
            btn_text: "Tôi đã đọc và hiểu các khuyến nghị này",
        },
    },
    flash_card: {
        yes_text: "Có",
        no_text: "Không",
    },
    complete_strategy_modal: {
        title: "Chúc mừng!",
        description: "Bạn đã hoàn thành bộ bài tập này.",
        btn_text: "Đi đến danh sách hep",
    },
    talk_to_expert: {
        title: "Nói chuyện với Chuyên gia",
        breadcrumb: "Nói chuyện với Chuyên gia!",
        book_now: "Đặt ngay",
        powered_by: "được cung cấp bởi",
    },
    find_clinic: {
        title: "Tìm Phòng khám",
        search: "Nhập vị trí",
        breadcrumb: "Tìm Phòng khám",
        back_title: "Địa điểm",
        result_text: "Kết quả tìm kiếm",
        pagination_label: "Hiển thị:",
        pagination_text: "Mục",
        toggle_list_map_label: "Xem:",
        list_text: "Danh sách",
        map_text: "Bản đồ",
        print_text: "in",
        not_found: "Không tìm thấy Phòng khám",
    },
    messages: {
        title: "Tin nhắn",
        new_message: "Tin nhắn mới",
        welcome: "Chào mừng",
        you_are_signed_in_as: "Bạn đã đăng nhập là",
        hi: "Xin chào",
        you_are_disconnected: "Bạn hiện đang ngắt kết nối khỏi internet.",
        practice: "Thực hành",
        provider: "Nhà cung cấp",
        could_not_send_msg: "Không thể gửi tin nhắn.",
        some_files_removed: "Một số tệp trên 100 MB đã bị xóa.",
        say_hello: "Nói Xin chào",
        hello_text: "Xin chào!",
        start_conversation: "Bắt đầu cuộc trò chuyện mới của bạn",
        send: "Gửi",
        textbox_placeholder: "Bắt đầu nhập...",
        search_placeholder: "Tìm kiếm...",
        no_provider: "Không có nhà cung cấp nào được chỉ định.",
        no_active_chat:
            "Bạn không có cuộc trò chuyện nào đang hoạt động. Bắt đầu cuộc trò chuyện mới bằng cách nhấp vào nút tin nhắn mới.",
    },
    header_messages: {
        title: "Tin nhắn",
        view_all: "XEM TẤT CẢ",
        no_messages: "Chưa có tin nhắn nào để hiển thị",
    },
    support: {
        title: "Hỗ trợ",
        tech_support_email: "Email Hỗ trợ Kỹ thuật",
        other_question: "Câu hỏi khác",
        section1_title:
            "Hỗ trợ Lâm sàng (Vấn đề Liên quan đến Chăm sóc Sức khỏe)",
        section1_description:
            "Trong trường hợp khẩn cấp đe dọa tính mạng hoặc y tế, hãy gọi 911 ngay lập tức. Đối với các câu hỏi chăm sóc sức khỏe không khẩn cấp, vui lòng liên hệ với",
        section2_title: "Hỗ trợ Kỹ thuật (Vấn đề Liên quan đến Phần mềm)",
        section2_description:
            "Hỗ trợ kỹ thuật của chúng tôi có sẵn từ 7:00 sáng đến 4:00 chiều PST, từ Thứ Hai đến Thứ Sáu, trừ các ngày lễ lớn. Bất kỳ yêu cầu nào được gửi ngoài giờ này sẽ được giải quyết vào ngày làm việc tiếp theo.",
        tech_support_email_address: "support@everflexplus.com",
    },
    injury_prevention_plan: {
        title: "Kế hoạch Phòng ngừa Chấn thương",
        heading: "Hiện tại không có chấn thương? Làm việc tại bàn cả ngày?",
        description:
            "Bắt đầu một chương trình chạy, hoặc muốn cải thiện trò chơi golf của bạn? Chúng tôi có các bài tập sẽ giúp ngăn ngừa đau và chấn thương cũng như tăng cường các khu vực bạn cần để cải thiện hiệu suất.",
        workplace_label: "Kế hoạch Nơi làm việc",
        sport_label: "Kế hoạch Thể thao",
        speciality_label: "Kế hoạch Chuyên biệt",
        generalized_label: "Kế hoạch Tổng quát",
        start_plan: "Bắt đầu Kế hoạch",
    },
    footer: {
        copyright_text: "Everflex Plus Health | Bản quyền thuộc về",
        pat_no_text:
            "Các sản phẩm này được bảo vệ bởi Bằng sáng chế số US 11,587,687 và các đơn đăng ký đang chờ xử lý khác.",
        about: "Về chúng tôi",
        security_statement: "Tuyên bố Bảo mật",
        privacy_policy: "Chính sách Bảo mật",
        terms_conditions: "Điều khoản & Điều kiện",
        contact: "Liên hệ",
        support: "Hỗ trợ",
        faqs: "Câu hỏi thường gặp",
        patent_information_text: "Thông tin Bằng sáng chế",
    },
    my_profile: {
        head_title_text: "Hồ sơ",
        my_account_text: "Tài khoản của tôi",
        tab: {
            account_information_text: "Thông tin Tài khoản",
            communication_preferences_text: "Tùy chọn Giao tiếp",
            change_password_text: "Đổi Mật khẩu",
        },
        account_information_form: {
            page_heading_text: "Thông tin Tài khoản",
            first_name_field: {
                label_text: "Tên",
                placeholder_text: "Nhập Tên",
            },
            last_name_field: {
                label_text: "Họ",
                placeholder_text: "Nhập Họ",
            },
            dob_field: {
                label_text: "Ngày sinh",
                placeholder_text: "Ngày sinh (MM/DD/YYYY)",
            },
            email_field: {
                label_text: "Email/Tên đăng nhập",
                placeholder_text: "Nhập Email/Tên đăng nhập",
            },
            phone_number_field: {
                label_text: "Số điện thoại",
                placeholder_text: "Nhập Số điện thoại",
            },
            otp_modal: {},
            unverified_email_text:
                "Email/Tên đăng nhập của bạn chưa được xác minh.",
            unverified_email_btn_text:
                "Nhấp vào đây để gửi lại email xác minh.",
            verification_link_sent_text:
                "Một liên kết xác minh mới đã được gửi đến địa chỉ email của bạn.",
            verification_link_not_sent:
                "Vui lòng cung cấp địa chỉ email để xác minh.",
            submit_btn_text: "Gửi",
        },
        change_password_form: {
            page_heading_text: "Thông tin Đăng nhập",
            current_password_field: {
                label_text: "Mật khẩu Hiện tại của bạn",
                placeholder_text: "Nhập Mật khẩu Hiện tại",
            },
            new_password_field: {
                label_text: "Mật khẩu Mới",
                placeholder_text: "Nhập Mật khẩu Mới",
            },
            confirm_password_field: {
                label_text: "Xác nhận Mật khẩu",
                placeholder_text: "Nhập Xác nhận Mật khẩu",
            },
            submit_btn_text: "Gửi",
        },
        communication_preference_form: {
            page_heading_text: "Tùy chọn Giao tiếp",
            table_headings: {
                setting_text: "Cài đặt",
                opt_in_text: "Đăng ký",
                opt_out_text: "Hủy đăng ký",
            },
            table_body: {
                email_notification_text: "Thông báo qua Email",
                sms_notification_text: "Thông báo qua Tin nhắn (SMS)",
            },
            submit_btn_text: "Gửi",
        },
    },
    password_requirement_text:
        "Mật khẩu phải chứa ít nhất 8 ký tự, bao gồm sự kết hợp của chữ hoa và chữ thường, số và ký hiệu.",
    language: {
        could_not_update: "Không thể cập nhật ngôn ngữ.",
    },
    error_page: {
        go_back_text: "Quay lại Trang chủ",
        403: {
            title: "403: Cấm",
            description: "Xin lỗi, bạn bị cấm truy cập trang này.",
        },
        404: {
            title: "404: Không tìm thấy Trang",
            description: "Xin lỗi, trang bạn đang tìm kiếm không thể tìm thấy.",
        },
        500: {
            title: "500: Lỗi Máy chủ",
            description:
                "Rất tiếc, có sự cố xảy ra trên máy chủ của chúng tôi.",
        },
        503: {
            title: "503: Dịch vụ Không khả dụng",
            description:
                "Xin lỗi, chúng tôi đang bảo trì. Vui lòng kiểm tra lại sau.",
        },
    },
    identify_injury: {
        select: {
            front: "Mặt trước",
            back: "Mặt sau",
            heading: "Chấn thương của tôi",
            page_title: "Chấn thương của tôi",
            okay: "Được",
            tell_me: "nói cho tôi",
            about_pain: "về cơn đau của bạn",
            answer_question:
                "Trả lời một số câu hỏi, và nhận một loạt các bài tập được cá nhân hóa dựa trên bằng chứng và được các nhà vật lý trị liệu lựa chọn để giúp bạn giảm đau nhẹ nhàng cho 23 bệnh thường gặp nhất. Nếu bạn không phù hợp với một mô hình chấn thương, bạn có thể truy cập tự giúp đỡ với các Kế hoạch Tổng quát của chúng tôi, tìm một phòng khám vật lý trị liệu gần bạn, hoặc có một cuộc hẹn với một nhà cung cấp trực tuyến.",
            select_body_part: "Chọn một phần cơ thể mà bạn đang gặp vấn đề:",
            next: "Tiếp theo",
            body_part_not_found: "Không tìm thấy phần cơ thể.",
        },
        show: {
            no_question_found: "Không tìm thấy câu hỏi",
            next: "Tiếp theo",
            heading: "Xác định Chấn thương của tôi",
            page_title: "Xác định Chấn thương của tôi",
            play_video_info:
                "Phát video để nghe giải thích chi tiết hơn về câu hỏi từ nhà vật lý trị liệu ảo của bạn.",
        },
        body_part: {
            heading: (bodyPart) => `Khám phá Đánh giá ${bodyPart}`,
            sub_heading: (currentBodyPart, newBodyPart) =>
                `Không tìm thấy sự giảm đau từ các bài tập ${currentBodyPart}? Chuyển hướng để đánh giá và giải quyết các vấn đề liên quan đến ${newBodyPart}.`,
            explore_plans: "Khám phá Kế hoạch",
        },
        fallout: {
            heading:
                "Dựa trên thông tin bạn cung cấp, bạn không thuộc một trong những mô hình chấn thương của chúng tôi.",
            sub_heading:
                "Chúng tôi khuyến nghị bạn nên nói chuyện với một nhà vật lý trị liệu có giấy phép để đảm bảo bạn nhận được sự chăm sóc tốt nhất có thể. Chúng tôi cung cấp các tùy chọn sau để phù hợp với nhu cầu của bạn:",
            talk_to_expert: "Nói chuyện với chuyên gia",
            find_clinic: "Tìm phòng khám",
        },
        generalized: {
            heading: "Khám phá Kế hoạch Tổng quát",
            sub_heading:
                "Không tìm thấy những gì bạn cần? Chuyển hướng đến một loạt các tùy chọn rộng hơn để quản lý cơn đau của bạn hoặc nâng cao bài tập của bạn.",
            explore_plans: "Khám phá Kế hoạch",
            not_ready_to_speak_heading: "Chưa sẵn sàng để nói chuyện với một chuyên gia? Khám phá kế hoạch chung của chúng tôi.",
            not_ready_to_speak_sub_heading: "Nhấp vào bên dưới để có thêm tùy chọn giúp kiểm soát cơn đau hoặc chấn thương của bạn",
        },
        hep: {
            heading: "Làm tốt lắm 🎉",
            sub_heading:
                "Các câu trả lời của bạn đã được khớp thành công với một mô hình chấn thương đã được thiết lập. Chúng tôi đã chỉ định cho bạn một kế hoạch phục hồi phù hợp với những kết quả này.",
            get_started: "bắt đầu",
        },
        all_body_parts: {
            neck: "Cổ",
            shoulder: "Vai",
            elbow_hand: "Khuỷu tay & Bàn tay",
            elbow_arm: "Khuỷu tay & Cánh tay",
            wrist_hand: "Cổ tay & Bàn tay",
            back: "Lưng",
            hip: "Hông",
            knee: "Đầu gối",
            knee_leg: "Đầu gối & Chân",
            ankle_foot: "Mắt cá chân & Bàn chân",
        },
        something_went_wrong_self_help_logs:
            "Có sự cố xảy ra khi lưu nhật ký tự giúp đỡ của bạn.",
    },
    cms_pages: {
        about: {
            breadcrumb_text: "Giới thiệu",
            head_text: "Giới thiệu",
            title_text: "Giới thiệu",
        },
        security_statement: {
            breadcrumb_text: "Tuyên bố Bảo mật",
            head_text: "Tuyên bố Bảo mật",
            title_text: "Tuyên bố Bảo mật",
        },
        privacy: {
            breadcrumb_text: "Chính sách Bảo mật",
            head_text: "Chính sách Bảo mật",
            title_text: "Chính sách Bảo mật",
        },
        terms: {
            breadcrumb_text: "Điều khoản & Điều kiện",
            head_text: "Điều khoản & Điều kiện",
            title_text: "Điều khoản & Điều kiện",
        },
        faq: {
            breadcrumb_text: "Câu hỏi Thường gặp",
            head_text: "Câu hỏi Thường gặp",
            title_text: "Câu hỏi Thường gặp",
        },
        patent: {
            breadcrumb_text: "Thông tin Bằng sáng chế",
            head_text: "Thông tin Bằng sáng chế",
            title_text: "Thông tin Bằng sáng chế",
        },
        no_data_text: "Không tìm thấy dữ liệu!",
    },
    accept_terms: {
        i_agree_text:
            "Tôi đồng ý với các điều khoản và điều kiện như đã nêu ở trên.",
        yes_btn_text: "Có",
        no_btn_text: "Không",
    },
    hep_restart: {
        check_in: "Đã đến lúc Kiểm tra",
        completed: "Kế hoạch của bạn đã hoàn thành!",
        hey: "Xin chào,",
        complete_plan_desc: `Chúng tôi rất vui mừng được kỷ niệm tiến bộ tuyệt vời mà bạn đã đạt được trong 21 ngày qua với bạn!`,
        take_a_moment: `Hãy dành một chút thời gian để suy ngẫm về cảm giác của bạn cho đến nay.`,
        feeling_better: `Cảm thấy tốt hơn? Tuyệt vời!`,
        current_plan_desc: `Bạn có thể nhấp vào nút HOÀN THÀNH KẾ HOẠCH HIỆN TẠI của tôi bên dưới. Chúc sức khỏe của bạn!`,
        resume_plan_desc: `Để tiếp tục kế hoạch của bạn và duy trì động lực, hãy nhấp vào nút TIẾP TỤC KẾ HOẠCH của tôi bên dưới.`,
        clinic_desc: `Nếu bạn chưa thấy nhiều tiến bộ hoặc cần thêm hỗ trợ, chúng tôi luôn sẵn sàng giúp đỡ! Không sao nếu bạn tạm dừng. Chúng tôi khuyến nghị bạn nên trò chuyện với chuyên gia chăm sóc sức khỏe để nhận được hướng dẫn cá nhân hóa.`,
        top_priority: `Sức khỏe của bạn là ưu tiên hàng đầu của chúng tôi.`,
        support_you: `Chúng tôi ở đây để hỗ trợ bạn từng bước.`,
        complete_plan_btn: `Hoàn thành kế hoạch hiện tại của tôi`,
        resume_plan_btn: `Tiếp tục kế hoạch hiện tại của tôi`,
        clinic_btn: "Tìm chuyên gia chăm sóc sức khỏe gần bạn",
        keep_moment: "Bạn có muốn duy trì động lực không?",
        is_completed_resume_plan: `Nhấp vào nút TIẾP TỤC KẾ HOẠCH của tôi bên dưới.`,
    },
    header_module: {
        notification_section: {
            popup_heading: "Thông báo",
            view_all_text: "Xem Tất cả",
            no_data_text: "Không có thông báo nào để hiển thị.",
        },
        select_preferred_language: "Chọn Ngôn ngữ Ưa thích",
        my_account: "Tài khoản của tôi",
        sign_out: "Đăng xuất",
        logout_go_back: "Đăng xuất/Quay lại",
    },
    notification_center: {
        list: {
            page_title: "Danh sách Trung tâm Thông báo",
            page_heading: "Tất cả Thông báo",
            no_record: "Không tìm thấy Thông báo",
        },
        show: {
            page_title: "Hiển thị Trung tâm Thông báo",
            page_heading: "Chi tiết Thông báo",
        },
        breadcrumb: {
            notification_text: "Tất cả Thông báo",
        },
        action: {
            view: "Xem",
            delete: "Xóa",
            delete_confirm: "Bạn có chắc chắn muốn xóa thông báo này không?",
        },
    },
    general_error: "Đã xảy ra lỗi. Vui lòng thử lại sau.",
    start_screen: {
        title: "Nhập email hoặc số điện thoại của bạn",
        heading: "Nhập email hoặc số điện thoại của bạn",
        email_placeholder: "Nhập email hoặc số điện thoại của bạn",
        btn_text: "Tiếp theo",
        account_disabled_error:
            "Tài khoản của bạn đã bị vô hiệu hóa, Vui lòng liên hệ hỗ trợ.",
        invite_sent:
            "Email mời đã được gửi thành công, vui lòng kiểm tra email của bạn.",
        invite_sent_by_text: "Tin nhắn mời đã được gửi",
        send_invite_by_text: "Gửi lời mời qua tin nhắn thay thế",
        account_not_found: "Không tìm thấy tài khoản!",
        choose_account: "Chọn Phòng khám của bạn để Tiếp tục!",
    },
    invite_modal: {
        heading: "Chúng tôi đã tìm thấy tài khoản của bạn",
        sub_heading:
            "Tài khoản của bạn đã được tạo, nhưng quá trình đăng ký chưa hoàn tất. Vui lòng kiểm tra email của bạn để nhận lời mời hoàn tất đăng ký, hoặc yêu cầu lời mời mới nếu cần.",
        btn_text: "Gửi lại Email Mời Tài khoản",
        btn_text_sms: "Gửi lại SMS Mời Tài khoản",
    },
    preference_modal: {
        acceptance_text: (clinicName) =>
            `Tôi ủy quyền rằng các thông tin liên lạc sau từ ${clinicName} sẽ được gửi đến tôi bằng các phương tiện điện tử đã cung cấp. Tôi hiểu rằng một số hình thức liên lạc điện tử có thể không an toàn, tạo ra nguy cơ tiết lộ không đúng cách cho các cá nhân không được phép.`,
        acceptance_text_2: `Tôi sẵn sàng chấp nhận rủi ro đó và sẽ không giữ trách nhiệm cho phòng khám nếu xảy ra sự cố như vậy.`,
        sms_notification_text: `Thông báo qua Tin nhắn (SMS) - khi phù hợp với kế hoạch phục hồi của bạn và được nhà cung cấp của bạn chỉ định`,
        email_notification_text: `Thông báo qua Email (cập nhật quan trọng, nhắc nhở thông tin liên quan đến chăm sóc sức khỏe và thỉnh thoảng là các tin nhắn tiếp thị)`,
        marketing_text: "Email Tiếp Thị - Nhận nội dung giáo dục, dịch vụ hoặc cập nhật sản phẩm phù hợp với sở thích và hoạt động trên cổng thông tin của bạn. LƯU Ý: Chúng tôi KHÔNG BAO GIỜ bán thông tin của bạn cho bên thứ ba.",
        clinical_text: "Email Lâm Sàng - Nhận thông tin quan trọng và cập nhật liên quan đến quá trình điều trị và hành trình chăm sóc sức khỏe của bạn.",
        system_text: "Email Thông Báo Hệ Thống - Luôn cập nhật với các email quan trọng về cập nhật hệ thống và cảnh báo bảo mật.",
        system: {
            header: "Email Thông Báo Hệ Thống",
            subtext: "Luôn cập nhật với các email quan trọng về cập nhật hệ thống và cảnh báo bảo mật.",
        },
        clinical: {
            header: "Email Lâm Sàng",
            subtext: "Nhận thông tin quan trọng và cập nhật liên quan đến quá trình điều trị và hành trình chăm sóc sức khỏe của bạn.",
        },
        marketing: {
            header: "Email Tiếp Thị",
            subtext: "Nhận nội dung giáo dục, dịch vụ hoặc cập nhật sản phẩm phù hợp với sở thích và hoạt động trên cổng thông tin của bạn. LƯU Ý: Chúng tôi KHÔNG BAO GIỜ bán thông tin của bạn cho bên thứ ba.",
        },
        accept_btn_text: "Chấp nhận",
        submit_btn_text: "Gửi",
        enter_phone: "NHẬP SỐ ĐIỆN THOẠI DI ĐỘNG CỦA BẠN",
        default_timezone_text: (defaultTimezone) =>
            `Chúng tôi sẽ gửi cho bạn một tin nhắn nhắc nhở để thực hiện các bài tập của bạn vào lúc 6:00 PM theo giờ địa phương. Có vẻ như bạn đang ở múi giờ ${defaultTimezone}, có đúng không?`,
        timezone_required: "Múi giờ là bắt buộc.",
        saved: "Tùy chọn đã được lưu.",
        no_btn_text: "Không",
        yes_btn_text: "Có",
        select_timezone: "Chọn Múi giờ",
        select_your_timezone: "Chọn múi giờ của bạn",
    },
    set_new_password: {
        heading: "ĐẶT MẬT KHẨU MỚI CỦA BẠN",
        sub_heading:
            "Để tăng cường bảo mật, vui lòng nhập mật khẩu hiện tại của bạn do quản trị viên cung cấp và sau đó đặt mật khẩu mới của bạn.",
        current_password_label: "Mật khẩu Hiện tại",
        current_password_placeholder: "Nhập Mật khẩu Hiện tại",
        new_password_label: "Mật khẩu Mới",
        new_password_placeholder: "Nhập Mật khẩu Mới",
        confirm_password_label: "Xác nhận mật khẩu mới",
        confirm_password_placeholder: "Xác nhận mật khẩu",
        save_btn_text: "Lưu Mật khẩu",
    },
    hep_status: {
        completed: "Hoàn thành",
        self_assigned: "Tự Chỉ định",
        independent: "Chương trình Tự học tại Nhà",
    },
    level: "Cấp độ",
    sec: "Giây",
    days: "Ngày",
    repetitions: "Lần lặp lại",
    lbs: "Pound",
    time: "Thời gian",
    allLanguages: {
        en: "Tiếng Anh",
        es: "Tiếng Tây Ban Nha",
        fr: "Tiếng Pháp",
        zh: "Tiếng Trung (Giản thể)",
        "zh-TW": "Tiếng Trung (Phồn thể)",
        tl: "Tiếng Tagalog",
        vi: "Tiếng Việt",
        ar: "Tiếng Ả Rập",
        ko: "Tiếng Hàn",
        ru: "Tiếng Nga",
        hi: "Tiếng Hindi",
        sw: "Tiếng Swahili",
    },
    outcomes: {
        next: "Tiếp theo",
        back: "Quay lại",
        continue: "Tiếp tục",
        finish: "Hoàn thành",
        response_title: "Cảm ơn bạn đã phản hồi.",
        groc: {
            title: "Đánh giá Tiến trình của Bạn Theo Thời Gian",
            subtitle: "Hãy suy ngẫm về tình trạng của bạn so với LẦN ĐẦU tiên bạn đến hoặc khi bắt đầu điều trị. Chọn tùy chọn mô tả tốt nhất sự thay đổi tổng thể mà bạn đã trải qua kể từ thời điểm đó. Tập trung vào cả những thay đổi tích cực và tiêu cực, bao gồm bất kỳ khía cạnh nào của tình trạng đã cải thiện hoặc xấu đi.",
            text_neg_7: "Tệ hơn nhiều",
            text_neg_4: "Tệ hơn",
            text_0: "Không thay đổi",
            text_4: "Tốt hơn",
            text_7: "Tốt hơn nhiều",
        },
        psfs: {
            title: "Đánh giá Những Thử thách Hoạt động Hàng ngày của Bạn",
            subtitle: "Vui lòng liệt kê 3 hoạt động quan trọng mà bạn không thể thực hiện hoặc gặp khó khăn do tình trạng của mình. Đối với mỗi hoạt động, vui lòng cung cấp điểm hoạt động cho mức độ khó khăn hiện tại của bạn tính đến hôm nay.",
            text_0: "Không thể thực hiện",
            text_10: "Có thể thực hiện tốt như trước khi bị thương",
            activity: "Hoạt động",
            text_0: "Không thể thực hiện",
            text_10: "Có thể thực hiện tốt như trước khi bị thương",
        }
    }
};
