// @ts-nocheck
export default {
    welcome: "Bienvenido",
    email_address: "Dirección de correo electrónico",
    email_or_phone: "Dirección de correo electrónico o número de teléfono",
    enter_your_email: "Ingrese su correo electrónico",
    enter_your_email_or_phone: "Ingrese su dirección de correo electrónico o número de teléfono",
    phone_placeholder: "número de teléfono",
    password: "Contraseña",
    confirm_password: "confirmar Contraseña",
    log_in: "Acceso",
    login: "Acceso",
    forgot_password: "¿Has olvidado tu contraseña?",
    forgot_password_title: "Has olvidado tu contraseña",
    keep_me_logged_in: "Mantenme conectado",
    sign_in: "Iniciar sesión",
    not_registered: "¿Aún no estás registrado?",
    create_account: "Crear una cuenta",
    sign_up: "Inscribirse",
    first_name: "Nombre de pila",
    last_name: "Apellido",
    dob_placeholder: "Fecha de nacimiento (DD/MM/AAAA)",
    submit: "Entregar",
    already_have_account: "¿Ya tienes una cuenta?",
    why_need_dob_title: "¿Por qué necesitamos esto?",
    why_need_dob_text: `Al utilizar este Sitio, usted declara, reconoce y acepta que tiene al menos 14 años de edad, o si es menor de 18 años pero tiene al menos 14 años (un "Menor"), que está utilizando el Sitio con el consentimiento de sus padres o tutor legal y que ha recibido el permiso de sus padres o tutor legal para utilizar el Sitio y aceptar sus Términos. Si usted es padre o tutor legal de un Menor, por la presente acepta vincular al Menor a estos Términos e indemnizar y eximir completamente de responsabilidad a la Compañía si el Menor infringe cualquiera de estos Términos. Si no tiene al menos 14 años, no puede utilizar el Sitio en ningún momento ni de ninguna manera ni enviar información a la Compañía o al Sitio.  NOTA: Hay algunas instancias de este software que requieren que los usuarios tengan al menos 14 años, al menos 16 años o al menos 18 años.  Si se le niega el registro después de ingresar su fecha de nacimiento es porque el portal específico en el que se está registrando tiene uno de estos requisitos.`,
    go_back: "Volver",
    email_verification: "Verificación de correo electrónico",
    log_out: "Finalizar la sesión",
    resend_email_verification: "Reenviar correo electrónico de verificación",
    resend_text_verification: "Reenviar texto de verificación",
    send_email_verification_instead: "En su lugar, envíe un correo electrónico de verificación",
    send_text_verification_instead: "En su lugar, envíe un mensaje de texto de verificación",
    reset_password: "Restablecer contraseña",
    accept_invite: "Aceptar invitación",
    my_hep: "Mi plan de ejercicios en casa",
    plan_created: "Plan creado",
    remove_plan: "Eliminar plano",
    remove_plan_confirm_text: "¿Estás seguro de que quieres eliminar este plan?",
    duplicate_plan_check_text:
        "Ya tienes este plan asignado. ¿Está seguro de que desea reasignar este plan?",
    duplicate_plan_modal: {
        yes_text: "Sí",
        no_text: "Ir al panel",
    },
    translation_disclaimer: {
        title: "Descargo de responsabilidad de traducción",
        subtitle: "Tenga en cuenta que nuestras traducciones funcionan con software de inteligencia artificial, que tiene aproximadamente un 98 % de precisión. Para garantizar una comunicación clara y precisa, recomendamos solicitar aclaraciones sobre cualquier traducción que parezca poco clara o incorrecta. También se recomienda utilizar un lenguaje sencillo y evitar la jerga para minimizar posibles malentendidos.",
        accept_button_text: "ENTIENDO Y ACEPTO USAR BAJO MI PROPIO RIESGO",
    },
    provider: "Proveedor",
    self_help_text: "Educación de autoayuda",
    show_all: "Mostrar todo",
    no_record: "No se encontró ningún registro",
    no_heps: "Aquí es donde encontrará sus Planes de recuperación que fueron creados después de que su proveedor de atención médica se los asignó o los autoasignó a través de cualquiera de nuestras herramientas de autoayuda que puedan estar disponibles para usted. Actualmente no tienes ninguno asignado.",
    what_to_do: "que hacer",
    hold_time: "Tiempo de espera",
    day_per_week: "Día por semana",
    equipment: "Equipo",
    sets: "Conjuntos",
    reps: "Reps",
    weight: "Peso",
    in_pounds: "En libras",
    time_per_day: "Tiempo por día",
    starting_position: "Posición inicial",
    description: "Descripción",
    what_you_feel: "Lo que deberías sentir",
    too_easy: "Demasiado fácil",
    too_hard: "Demasiado difícil",
    just_right: "Justo a la derecha",
    exercise: "Ejercicio",
    education: "Educación",
    back_to_list: "Volver a la lista",
    about_this_exercise: "¿Qué opinas de este ejercicio?",
    attachement: "Adjunto",
    exercise_completed: "Marcar ejercicio como completado",
    no_details: "No hay detalles disponibles.",
    hep_details: {
        hard_exercise_modal: {
            title: "Ajuste necesario para el ejercicio.",
            description_line1:
                "Este es el ejercicio más sencillo de esta estrategia, por lo que no podemos ofrecer uno más sencillo en este momento.",
            description_line2:
                "Si encuentra algún ejercicio demasiado difícil, tiene inquietudes o no está seguro de qué hacer a continuación, deténgase inmediatamente y consulte a un profesional de la salud o a su proveedor asignado si le recetaron este plan de recuperación.",
            btn_text: "He leído y entiendo estas recomendaciones.",
        },
    },
    flash_card: {
        yes_text: "Sí",
        no_text: "No",
    },
    complete_strategy_modal: {
        title: "¡Felicitaciones!",
        description: "Has completado este conjunto de ejercicios.",
        btn_text: "Ir a la lista de ayuda",
    },
    talk_to_expert: {
        title: "Habla con un experto",
        breadcrumb: "¡Habla con un experto!",
        book_now: "Reserva ahora",
        powered_by: "Desarrollado por",
    },
    find_clinic: {
        title: "Encontrar una clínica",
        search: "Introduce la ubicación",
        breadcrumb: "Encontrar una clínica",
        back_title: "Ubicaciones",
        result_text: "Resultados de la búsqueda",
        pagination_label: "Mostrar:",
        pagination_text: "Entradas",
        toggle_list_map_label: "Ver:",
        list_text: "Lista",
        map_text: "Mapa",
        print_text: "imprimir",
        not_found: "No se encontraron clínicas",
    },
    messages: {
        title: "Mensajes",
        new_message: "New Message",
        welcome: "Bienvenido",
        you_are_signed_in_as: "Has iniciado sesión como",
        hi: "Hola",
        you_are_disconnected: "Actualmente estás desconectado de Internet.",
        practice: "Práctica",
        provider: "Proveedor",
        could_not_send_msg: "No se pudo enviar el mensaje.",
        some_files_removed:
            "Se eliminaron algunos archivos de más de 100 MB.",
        say_hello: "Di hola",
        hello_text: "¡Hola!",
        start_conversation: "Comienza tu nueva conversación",
        send: "Enviar",
        textbox_placeholder: "Start typing...",
        search_placeholder: "Buscar...",
        no_provider: "Ningún proveedor asignado.",
        no_active_chat:
            "No tienes chats activos. Inicie un nuevo chat haciendo clic en el botón de mensaje nuevo.",
    },
    header_messages: {
        title: "Mensajes",
        view_all: "VER TODO",
        no_messages: "No hay mensajes para mostrar aún",
    },
    support: {
        title: "Soporte",
        tech_support_email: "Correo Electrónico de Soporte Técnico",
        other_question: "Otras Preguntas",
        section1_title: "Soporte Clínico (Problemas Relacionados con la Salud)",
        section1_description:
            "En caso de una emergencia médica o que ponga en peligro la vida, marque 911 de inmediato. Para consultas de salud que no sean de emergencia, comuníquese con",
        section2_title:
            "Soporte Técnico (Problemas Relacionados con el Software)",
        section2_description:
            "Nuestro soporte técnico está disponible de 7:00 am a 4:00 pm PST, de lunes a viernes, excluyendo los principales días festivos. Cualquier envío realizado fuera de este horario será atendido el siguiente día hábil.",
        tech_support_email_address: "support@everflexplus.com",
    },
    injury_prevention_plan: {
        title: "Plan de Prevención de Lesiones",
        heading:
            "¿No tienes una lesión actualmente? ¿Trabajas en un escritorio todo el día?",
        description:
            "¿Estás comenzando un programa de running o quieres mejorar tu juego de golf? Tenemos ejercicios que ayudarán a prevenir el dolor y las lesiones, así como a fortalecer las áreas que necesitas para mejorar el rendimiento.",
        workplace_label: "Planes de Trabajo",
        sport_label: "Planes Deportivos",
        speciality_label: "Planes Especializados",
        generalized_label: "Planes Generalizados",
        start_plan: "Iniciar Plan",
    },
    footer: {
        copyright_text: "Everflex Plus Health | Todos los Derechos Reservados",
        pat_no_text:
            "Estos productos están cubiertos por la Patente No. US 11,587,687 y otras aplicaciones pendientes.",
        about: "Acerca de",
        security_statement: "Declaración de Seguridad",
        privacy_policy: "Política de Privacidad",
        terms_conditions: "Términos y Condiciones",
        contact: "Contacto",
        support: "Soporte",
        faqs: "Preguntas Frecuentes",
        patent_information_text: "Información de Patentes",
    },
    my_profile: {
        head_title_text: "Perfil",
        my_account_text: "Mi Cuenta",
        tab: {
            account_information_text: "Información de la Cuenta",
            communication_preferences_text: "Preferencias de Comunicación",
            change_password_text: "Cambiar Contraseña",
        },
        account_information_form: {
            page_heading_text: "Información de la Cuenta",
            first_name_field: {
                label_text: "Nombre",
                placeholder_text: "Ingrese Nombre",
            },
            last_name_field: {
                label_text: "Apellido",
                placeholder_text: "Ingrese Apellido",
            },
            dob_field: {
                label_text: "Fecha de Nacimiento",
                placeholder_text: "Fecha de nacimiento (MM/DD/AAAA)",
            },
            email_field: {
                label_text: "Correo Electrónico/Nombre de Usuario",
                placeholder_text:
                    "Ingrese Correo Electrónico/Nombre de Usuario",
            },
            phone_number_field: {
                label_text: "Número de Teléfono",
                placeholder_text: "Ingrese Número de Teléfono",
            },
            otp_modal: {},
            unverified_email_text:
                "Su correo electrónico/nombre de usuario no está verificado.",
            unverified_email_btn_text:
                "Haga clic aquí para reenviar el correo de verificación.",
            verification_link_sent_text:
                "Se ha enviado un nuevo enlace de verificación a su dirección de correo electrónico.",
            verification_link_not_sent:
                "Por favor, proporcione una dirección de correo electrónico para verificarla.",
            submit_btn_text: "Enviar",
        },
        change_password_form: {
            page_heading_text: "Información de Inicio de Sesión",
            current_password_field: {
                label_text: "Su Contraseña Actual",
                placeholder_text: "Ingrese Contraseña Actual",
            },
            new_password_field: {
                label_text: "Nueva Contraseña",
                placeholder_text: "Ingrese Nueva Contraseña",
            },
            confirm_password_field: {
                label_text: "Confirmar Contraseña",
                placeholder_text: "Ingrese Confirmar Contraseña",
            },
            submit_btn_text: "Enviar",
        },
        communication_preference_form: {
            page_heading_text: "Preferencias de Comunicación",
            table_headings: {
                setting_text: "Configuración",
                opt_in_text: "Optar por Recibir",
                opt_out_text: "Optar por No Recibir",
            },
            table_body: {
                email_notification_text:
                    "Notificaciones por Correo Electrónico",
                sms_notification_text: "Notificaciones por SMS",
            },
            submit_btn_text: "Enviar",
        },
    },
    password_requirement_text:
        "La contraseña debe contener un mínimo de 8 caracteres, incluyendo una combinación de letras mayúsculas y minúsculas, números y símbolos.",
    language: {
        could_not_update: "No se pudo actualizar el idioma.",
    },
    error_page: {
        go_back_text: "Volver a Inicio",
        403: {
            title: "403: Prohibido",
            description:
                "Lo siento, no tiene permiso para acceder a esta página.",
        },
        404: {
            title: "404: Página No Encontrada",
            description:
                "Lo siento, la página que está buscando no se pudo encontrar.",
        },
        500: {
            title: "500: Error del Servidor",
            description: "Vaya, algo salió mal en nuestros servidores.",
        },
        503: {
            title: "503: Servicio No Disponible",
            description:
                "Lo siento, estamos realizando mantenimiento. Por favor, vuelva pronto.",
        },
    },
    identify_injury: {
        select: {
            front: "Frente",
            back: "Espalda",
            heading: "Mi Lesión",
            page_title: "Mi lesión",
            okay: "De acuerdo",
            tell_me: "dime",
            about_pain: "sobre tu dolor",
            answer_question:
                "Responde algunas preguntas y recibe una serie personalizada de ejercicios basados en evidencia y curados por fisioterapeutas, diseñados para ayudarte a aliviar suavemente el dolor de 23 de las dolencias más comúnmente reportadas. Si no encajas en un patrón de lesión, puedes acceder a la autoayuda con nuestros Planes Generalizados, encontrar una clínica de fisioterapia cerca de ti o tener una consulta con un proveedor en línea.",
            select_body_part:
                "Selecciona una parte del cuerpo donde tengas problemas:",
            next: "Siguiente",
            body_part_not_found: "Parte del cuerpo no encontrada.",
        },
        show: {
            no_question_found: "No se encontró ninguna pregunta",
            next: "Siguiente",
            heading: "Identificar Mi Lesión",
            page_title: "Identificar Mi Lesión",
            play_video_info:
                "Reproduce el video para escuchar una explicación más detallada de la pregunta de tu fisioterapeuta virtual.",
        },
        body_part: {
            heading: (bodyPart) => `Explorar Evaluación de ${bodyPart}`,
            sub_heading: (currentBodyPart, newBodyPart) =>
                `¿No encontraste alivio con los ejercicios para ${currentBodyPart}? Redirigir para evaluar y abordar preocupaciones relacionadas con ${newBodyPart}.`,
            explore_plans: "Explorar Planes",
        },
        fallout: {
            heading:
                "Según la información que proporcionaste, no encajaste en uno de nuestros patrones de lesión.",
            sub_heading:
                "Se recomienda que hables con un fisioterapeuta licenciado para asegurarte de recibir la mejor atención posible. Ofrecemos las siguientes opciones para satisfacer tus necesidades:",
            talk_to_expert: "Habla con un experto",
            find_clinic: "Encuentra una clínica",
        },
        generalized: {
            heading: "Explorar Planes Generalizados",
            sub_heading:
                "¿No encontraste lo que necesitas? Redirige a una gama más amplia de opciones para manejar tu dolor o mejorar tus ejercicios.",
            explore_plans: "Explorar Planes",
            not_ready_to_speak_heading: "¿No estás listo para hablar con un experto? Explora nuestros planes generales.",
            not_ready_to_speak_sub_heading: "Haga clic a continuación para obtener más opciones que le ayudarán a controlar su dolor o lesión.",
        },
        hep: {
            heading: "Bien Hecho 🎉",
            sub_heading:
                "Tus respuestas se han emparejado exitosamente con un patrón de lesión establecido. Te hemos asignado un plan de recuperación que coincide con estos resultados.",
            get_started: "comenzar",
        },
        all_body_parts: {
            neck: "Cuello",
            shoulder: "Hombro",
            elbow_hand: "Codo y Mano",
            elbow_arm: "Codo / Brazo",
            wrist_hand: "Muñeca / Mano",
            back: "Espalda",
            hip: "Cadera",
            knee: "Rodilla",
            knee_leg: "Rodilla y Pierna",
            ankle_foot: "Tobillo y Pie",
        },
        something_went_wrong_self_help_logs:
            "Algo salió mal al guardar tu registro personal.",
    },
    cms_pages: {
        about: {
            breadcrumb_text: "Acerca de",
            head_text: "Acerca de",
            title_text: "Acerca de",
        },
        security_statement: {
            breadcrumb_text: "Declaración de Seguridad",
            head_text: "Declaración de Seguridad",
            title_text: "Declaración de Seguridad",
        },
        privacy: {
            breadcrumb_text: "Política de Privacidad",
            head_text: "Política de Privacidad",
            title_text: "Política de Privacidad",
        },
        terms: {
            breadcrumb_text: "Términos y Condiciones",
            head_text: "Términos y Condiciones",
            title_text: "Términos y Condiciones",
        },
        faq: {
            breadcrumb_text: "Preguntas Frecuentes",
            head_text: "Preguntas Frecuentes",
            title_text: "Preguntas Frecuentes",
        },
        patent: {
            breadcrumb_text: "Información de Patentes",
            head_text: "Información de Patentes",
            title_text: "Información de Patentes",
        },
        no_data_text: "¡No se encontraron datos!",
    },
    accept_terms: {
        i_agree_text:
            "Estoy de acuerdo con los términos y condiciones establecidos anteriormente.",
        yes_btn_text: "Sí",
        no_btn_text: "No",
    },
    hep_restart: {
        check_in: "Hora de Registrarse",
        completed: "¡Tu Plan está Completo!",
        hey: "Hola,",
        complete_plan_desc: `¡Estamos encantados de celebrar contigo el increíble progreso que has logrado en los últimos 21 días!`,
        take_a_moment: `Tómate un momento para reflexionar sobre cómo te sientes hasta ahora.`,
        feeling_better: `¿Te sientes mejor? ¡Genial!`,
        current_plan_desc: `Puedes hacer clic en el botón COMPLETAR MI PLAN ACTUAL a continuación. ¡Salud por tu bienestar!`,
        resume_plan_desc: `Para continuar con tu plan y mantener el impulso, haz clic en el botón REANUDAR MI PLAN a continuación.`,
        clinic_desc: `Si no has experimentado mucho progreso o necesitas un apoyo adicional, ¡te respaldamos! Está bien hacer una pausa. Recomendamos hablar con un profesional de la salud que pueda brindarte orientación personalizada.`,
        top_priority: `Tu bienestar es nuestra máxima prioridad.`,
        support_you: `Estamos aquí para apoyarte en cada paso del camino.`,
        complete_plan_btn: `Completar mi plan actual`,
        resume_plan_btn: `Reanudar mi plan actual`,
        clinic_btn: "Encuentra un profesional de la salud cerca de ti",
        keep_moment: "¿Te gustaría mantener el impulso?",
        is_completed_resume_plan: `Haz clic en el botón REANUDAR MI PLAN a continuación.`,
    },
    header_module: {
        notification_section: {
            popup_heading: "Notificaciones",
            view_all_text: "Ver Todo",
            no_data_text: "No hay notificaciones para mostrar.",
        },
        select_preferred_language: "Seleccionar Idioma Preferido",
        my_account: "Mi Cuenta",
        sign_out: "Cerrar Sesión",
        logout_go_back: "Cerrar Sesión/Volver",
    },
    notification_center: {
        list: {
            page_title: "Lista del Centro de Notificaciones",
            page_heading: "Todas las Notificaciones",
            no_record: "No se encontraron notificaciones",
        },
        show: {
            page_title: "Mostrar Centro de Notificaciones",
            page_heading: "Detalle de la Notificación",
        },
        breadcrumb: {
            notification_text: "Todas las Notificaciones",
        },
        action: {
            view: "Ver",
            delete: "Eliminar",
            delete_confirm:
                "¿Estás seguro de que deseas eliminar esta notificación?",
        },
    },
    general_error: "Algo salió mal. Por favor, inténtalo de nuevo más tarde.",
    start_screen: {
        title: "Ingresa tu correo electrónico o número de teléfono",
        heading: "Ingresa tu correo electrónico o número de teléfono",
        email_placeholder: "Ingresa tu correo electrónico o número de teléfono",
        btn_text: "Siguiente",
        account_disabled_error:
            "Tu cuenta está deshabilitada, por favor contacta con soporte.",
        invite_sent:
            "Correo de invitación enviado con éxito, por favor revisa tu correo.",
        invite_sent_by_text: "Texto de invitación enviado",
        send_invite_by_text: "Enviar invitación por texto en su lugar",
        account_not_found: "¡Cuenta no encontrada!",
        choose_account: "¡Elige tu clínica para continuar!",
    },
    invite_modal: {
        heading: "Hemos Encontrado Tu Cuenta",
        sub_heading:
            "Tu cuenta ha sido creada, pero el proceso de registro está incompleto. Por favor revisa tu correo electrónico para una invitación para completar tu registro, o solicita una nueva invitación si es necesario.",
        btn_text: "Reenviar Correo de Invitación de Cuenta",
        btn_text_sms: "Reenviar SMS de Invitación de Cuenta",
    },
    preference_modal: {
        acceptance_text: (clinicName) =>
            `Autorizo que las siguientes comunicaciones de ${clinicName} me sean entregadas por los medios electrónicos proporcionados. Entiendo que algunas formas de comunicación electrónica pueden no ser seguras, creando un riesgo de divulgación indebida a personas no autorizadas.`,
        acceptance_text_2: `Estoy dispuesto a aceptar ese riesgo, y no responsabilizaré a la práctica en caso de que ocurra tal incidente.`,
        sms_notification_text: `Notificaciones por Texto (SMS) Recordatorios - cuando sea apropiado para tu plan de recuperación y asignado por tu proveedor`,
        email_notification_text: `Notificaciones por Correo Electrónico (actualizaciones importantes, recordatorios de otra información relacionada con la salud y mensajes de marketing ocasionales)`,
        marketing_text: "Correos Electrónicos de Marketing - Recibe contenido educativo, servicios o actualizaciones de productos adaptados a tus intereses y actividad en el portal. NOTA: NUNCA venderemos tu información a terceros.",
        clinical_text: "Correos Electrónicos Clínicos - Recibe actualizaciones e información importante relacionada con tu tratamiento y proceso de bienestar.",
        system_text: "Correos Electrónicos de Notificación del Sistema - Mantente informado con correos críticos sobre actualizaciones del sistema y alertas de seguridad.",
        system: {
            header: "Correos Electrónicos de Notificación del Sistema",
            subtext: "Mantente informado con correos críticos sobre actualizaciones del sistema y alertas de seguridad.",
        },
        clinical: {
            header: "Correos Electrónicos Clínicos",
            subtext: "Recibe actualizaciones e información importante relacionada con tu tratamiento y proceso de bienestar.",
        },
        marketing: {
            header: "Correos Electrónicos de Marketing",
            subtext: "Recibe contenido educativo, servicios o actualizaciones de productos adaptados a tus intereses y actividad en el portal. NOTA: NUNCA venderemos tu información a terceros.",
        },
        accept_btn_text: "Aceptar",
        submit_btn_text: "Enviar",
        enter_phone: "INGRESA TU NÚMERO DE TELÉFONO MÓVIL",
        default_timezone_text: (defaultTimezone) =>
            `Te enviaremos un recordatorio por texto para hacer tus ejercicios a las 6:00 PM hora local. Parece que estás en la zona horaria ${defaultTimezone}, ¿es correcto?`,
        timezone_required: "Se requiere zona horaria.",
        saved: "Preferencias guardadas.",
        no_btn_text: "No",
        yes_btn_text: "Sí",
        select_timezone: "Seleccionar Zona Horaria",
        select_your_timezone: "Selecciona tu zona horaria",
    },
    set_new_password: {
        heading: "ESTABLECE TU NUEVA CONTRASEÑA",
        sub_heading:
            "Para mayor seguridad, por favor ingresa tu contraseña actual proporcionada por el administrador y luego establece tu nueva contraseña.",
        current_password_label: "Contraseña Actual",
        current_password_placeholder: "Ingresa Contraseña Actual",
        new_password_label: "Nueva Contraseña",
        new_password_placeholder: "Ingresa Nueva Contraseña",
        confirm_password_label: "Confirmar nueva contraseña",
        confirm_password_placeholder: "Confirmar contraseña",
        save_btn_text: "Guardar Contraseña",
    },
    hep_status: {
        completed: "Completado",
        self_assigned: "Auto Asignado",
        independent: "Programa de Hogar Independiente",
    },
    level: "Nivel",
    sec: "Seg",
    days: "Días",
    repetitions: "Repeticiones",
    lbs: "Libras",
    time: "Tiempo",
    allLanguages: {
        en: "Inglés",
        es: "Español",
        fr: "Francés",
        zh: "Chino (Simplificado)",
        "zh-TW": "Chino (Tradicional)",
        tl: "Tagalo",
        vi: "Vietnamita",
        ar: "Árabe",
        ko: "Coreano",
        ru: "Ruso",
        hi: "Hindi",
        sw: "Suajili",
    },
    outcomes: {
        next: "Siguiente",
        back: "Atrás",
        continue: "Continuar",
        finish: "Finalizar",
        response_title: "Gracias por tu opinión.",
        groc: {
            title: "Evalúa Tu Progreso Con el Tiempo",
            subtitle: "Reflexiona sobre tu condición en comparación con tu visita INICIAL o el comienzo del tratamiento. Selecciona la opción que mejor describa el cambio general que has experimentado desde entonces. Considera tanto los cambios positivos como los negativos, incluyendo cualquier aspecto de tu condición que haya mejorado o empeorado.",
            text_neg_7: "Mucho Peor",
            text_neg_4: "Peor",
            text_0: "Sin Cambios",
            text_4: "Mejor",
            text_7: "Mucho Mejor",
        },
        psfs: {
            title: "Evalúa Tus Desafíos en Actividades Diarias",
            subtitle: "Por favor, enumera 3 actividades importantes que no puedes realizar o con las que tienes dificultad debido a tu condición. Para cada actividad, proporciona una puntuación basada en el nivel de dificultad que experimentas hoy.",
            text_0: "No Puede Realizar",
            text_10: "Puede Realizar Tan Bien Como Antes de la Lesión",
            activity: "Actividad",
            text_0: "No Puede Realizar",
            text_10: "Puede Realizar Tan Bien Como Antes de la Lesión",
        }
    }
};
